import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { H1, TextSm, MarkdownHandler } from '@system'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    display: 'block',
  },
  text: {
    color: theme.palette.text.tertiary,
  },
  childrenClasses: {
    columnGap: 24,
    [theme.breakpoints.down('sm')]: {
      columnCount: 1,
    },
    [theme.breakpoints.up('sm')]: {
      columnCount: 2,
    },
    '&>*': {
      marginBlockStart: '0em',
      marginBlockEnd: '0em',
      paddingBottom: '12px',
      lineHeight: '21px',
      '& a': {
        color: theme.palette.text.tertiary,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
}))

const LongFormTextModule = (props) => {
  const classes = useStyles()
  const markdownText =
    (!!props.blok.text &&
      props.blok.text.length > 0 &&
      props.blok.text[0].markdown) ||
    ''

  const { shouldRenderH1 } = props.blok

  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <ContentContainer>
          <Box py="30px">
            <H1
              color="secondary"
              component={shouldRenderH1 ? 'h1' : 'h2'}
              className={classes.title}
            >
              {props.blok.title}
            </H1>
            <Box display="block" className={classes.text}>
              <TextSm component="div" className={classes.childrenClasses}>
                {<MarkdownHandler>{markdownText}</MarkdownHandler>}
              </TextSm>
            </Box>
          </Box>
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default LongFormTextModule
